.cls-1, .cls-15 {
    fill: none;
}

.cls-2 {
    fill: #231f20;
}

.cls-3 {
    isolation: isolate;
}

.cls-4 {
    opacity: 0.75;
    mix-blend-mode: multiply;
}

.cls-16, .cls-17, .cls-5 {
    fill: #fff;
}

.cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
    stroke: #000;
}

.cls-15, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
    stroke-miterlimit: 10;
}

.cls-6 {
    fill: #ffcf1f;
}

.cls-7 {
    fill: #b3b3b3;
}

.cls-8 {
    fill: #ed1c24;
}

.cls-9 {
    fill: #e6e6e6;
}

.cls-10 {
    fill: #f33;
    opacity: 0.67;
}

.cls-11 {
    fill: #0071bc;
}

.cls-12 {
    fill: #e2c9b0;
}

.cls-13 {
    fill: url(#linear-gradient);
}

.cls-14 {
    fill: url(#USGS_17_Sandy_Dry_Lake);
}

.cls-15 {
    stroke: #e6e6e6;
    stroke-width: 5px;
}

.cls-16 {
    opacity: 0.25;
}

.cls-17 {
    opacity: 0.15;
}