@font-face {
  font-family: BritannicBold;
  src: url(./styles/BritannicBold.ttf);
}

@font-face {
  font-family: Britannic;
  src: url(./styles/BritannicBold.ttf);
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
